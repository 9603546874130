import React from 'react'
import * as styles from '../styles/termin.module.css'
import Button from '../components/Button'

export default function Termin() {
  return (
    <div className={styles.terminContainer}>
      <h3>Jetz Termin vereinbaren!</h3>
      <h5>
        <span>Tel.: 030 991 70 90</span>
        <span>E-Mail: zahnarztpraxis@helledent.de</span>
      </h5>
      <a
        href="https://www.doctolib.de/zahnarztpraxis/berlin/helledent?utm_campaign=website-button&utm_source=helledent-website-button&utm_medium=referral&utm_content=option-8&utm_term=helledent"
        target="_blank" rel="noopener"
      >
        <Button name="termin vereinbaren" color="#53a447" bg="white" />
      </a>
    </div>
  )
}
