import React from 'react'
import { Map, Marker } from 'pigeon-maps'
import * as styles from '../styles/maps.module.css'
export default function PigeonMap() {
  return (
    <div className={styles.mapContainer}>
      <Map
        boxClassname={styles.maps}
        height={468}
        defaultCenter={[52.53148910242414, 13.62167478284556]}
        defaultZoom={16}
        maxZoom={16}
        metaWheelZoom={true}
        metaWheelZoomWarning="Verwenden Sie Strg + Scroll, um die Karte zu zoomen"
      >
        <Marker width={50} anchor={[52.53148910242414, 13.62167478284556]} />
      </Map>
    </div>
  )
}
