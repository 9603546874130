// extracted by mini-css-extract-plugin
export var submitContainer = "contactForm-module--submitContainer--bIrw2";
export var formContainer = "contactForm-module--formContainer--2oqxT";
export var inputBox = "contactForm-module--inputBox--1zPag";
export var textBox = "contactForm-module--textBox--3VPwL";
export var line = "contactForm-module--line--1K5qJ";
export var lineColor = "contactForm-module--lineColor--3etHh";
export var datenSchutz = "contactForm-module--datenSchutz--29EC4";
export var errorMessage = "contactForm-module--errorMessage--z5y7Y";
export var datenSchutzError = "contactForm-module--datenSchutzError--1s-Yh";
export var inputContainer = "contactForm-module--inputContainer--2jXav";