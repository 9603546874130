import React from 'react'

import Layout from '../components/Layout'
import HomePage from '../components/HomePage'
import ServicesPage from '../components/ServicesPage'
import UberUns from '../components/UberUns'
import Team from '../components/Team'
import ContactGrid from '../components/ContactGrid'
import ReviewPage from '../components/ReviewPage'
import Gallery3 from '../components/Gallery3'
import PigeonMap from '../components/PigeonMap'
import SimpleReactLightbox from 'simple-react-lightbox'
import Termin from '../components/Termin'
import { Helmet } from 'react-helmet'

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <title>Zahnärztin Veneta Bogdanova-HELLEDENT</title>
        <meta
          name="description"
          content="Zahnärztin Veneta Bogdanova-HELLEDENT, Louis-Lewin-Str. 8, 12627 Berlin. Ihr Wohlbefinden ist uns wichtig! Daher empfehlen wir Ihnen eine halbjährliche Kontrolle und bieten Ihnen eine optimale Betreuung."
        />
        <meta
          name="keywords"
          content="Veneta Bogdanova, Zahnärztin, Louis-Lewin-Str. 8, 12627 Berlin, Helledent, Berlin, 12627, Zahnärztin Veneta Bogdanova, Individualprophylaxe für Kinder mit Zahnputzschule, Zahnerhaltung, Ernährungsberatung, Implantatprothetik, Angstpatienten, Behandlung von Kindern, Zahnärztin Berlin Hellersdorf"
        />
        <meta
          property="og:title"
          content="Zahnärztin Veneta Bogdanova-HELLEDENT"
        />
        <meta property="og:type" content="website" />
        <meta
          property="og:description"
          content="Zahnärztin Veneta Bogdanova-HELLEDENT, Louis-Lewin-Str. 8, 12627 Berlin. Ihr Wohlbefinden ist uns wichtig! Daher empfehlen wir Ihnen eine halbjährliche Kontrolle und bieten Ihnen eine optimale Betreuung."
        />
        <meta property="og:image" content="helledent.png" />
        <meta property="og:locale" content="de" />
        <meta property="og:url" content="https://www.helledent.de/" />
        <link rel="canonical" href="https://www.helledent.de/" />
      </Helmet>
      <HomePage />
      <ServicesPage />
      <UberUns />
      <Team />
      <ReviewPage />
      <SimpleReactLightbox>
        <Gallery3 />
      </SimpleReactLightbox>
      <ContactGrid />
      <PigeonMap />
      <Termin />
    </Layout>
  )
}
