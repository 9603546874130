// extracted by mini-css-extract-plugin
export var gallery = "gallery3-module--gallery--3VYim";
export var images = "gallery3-module--images--1n2Rp";
export var horizontalna = "gallery3-module--horizontalna--2reWs";
export var image = "gallery3-module--image--2Pxcm";
export var vertikalna = "gallery3-module--vertikalna--3-EZi";
export var hor1 = "gallery3-module--hor1----S5p";
export var ver1 = "gallery3-module--ver1--3-Na7";
export var hor2 = "gallery3-module--hor2--28DlP";
export var ver3 = "gallery3-module--ver3--2A0i3";
export var ver4 = "gallery3-module--ver4--5Bhtz";
export var ver2 = "gallery3-module--ver2--25dRY";
export var hor3 = "gallery3-module--hor3--XM5Z0";
export var hor4 = "gallery3-module--hor4--3kAZ6";