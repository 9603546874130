import React from 'react'
import * as styles from '../styles/home.module.css'
import Phone from '../assets/icons/Phone'
import Clock from '../assets/icons/Clock'
import Address from '../assets/icons/Address'
import TimeTable from '../components/TimeTable'
import Button from './Button'

export default function HomePage() {
  return (
    <div className={styles.homePage} id="home">
      <div className={styles.line}></div>
      <img src="family.jpg" alt="photo of Veneta" />
      <div className={styles.line2}></div>
      <div className={styles.willkommen}>
        <h1>Willkommen</h1>
        <h2>Gesund beginnt im Mund!</h2>
        <p>
          Wissen Sie, dass der Mund wie eine Spiegel ist der den
          Gesundheitszustand unseres Körpers zeigt?
        </p>
        <p>
          Ihr Wohlbefinden ist uns wichtig! Daher empfehlen wir Ihnen eine
          halbjährliche Kontrolle und bieten Ihnen eine optimale Betreuung.
        </p>
        <a
          href="https://www.doctolib.de/zahnarztpraxis/berlin/helledent?utm_campaign=website-button&utm_source=helledent-website-button&utm_medium=referral&utm_content=option-8&utm_term=helledent"
          target="_blank" rel="noopener"
        >
          <Button name="termin vereinbaren" />
        </a>
        <a href="Anamnesebogen.pdf" download>
          <Button name="anamnese pdf" />
        </a>
      </div>
      <div className={styles.line3}></div>
      <div className={styles.greenSquare}>
        <div className={styles.square1}>
          <Clock height={37} />
          <h3>ÖFFNUNGSZEITEN</h3>
          <p>
            <strong>Mo</strong> 12:30-18:30 <strong>Di</strong> 8:30-14:00
          </p>
          <p>
            <strong>Mi</strong> 12:30-18:30
          </p>
          <p>
            <strong>Do</strong> 8:30-12:30 / 13:00-15:30{' '}
          </p>
          <p>
            <strong>Fr</strong> nach Vereinbarungen
          </p>
        </div>
        <div className={styles.lineSepa}></div>
        <div className={styles.square2}>
          <Address height={37} />
          <h3>ADRESSE</h3>
          <p>Zahnarztpraxis Helledent</p>
          <p>ZÄ Veneta Nenkova-Bogdanova </p>
          <p>Louis-Lewin-Straße 8,</p>
          <p>12627 Berlin</p>
        </div>
        <div className={styles.lineSepa2}></div>
        <div className={styles.square3}>
          <Phone height={37} />
          <h3>KONTAKTDATEN</h3>
          <p>Tel.: (030) 991 70 90 </p>
          <p>E-Mail: zahnarztpraxis@helledent.de</p>
          <p>Wir sprechen Deutsch,</p>
          <p>Englisch und Bulgarisch</p>
        </div>
      </div>
    </div>
  )
}
