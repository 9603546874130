import React from 'react'
import * as styles from '../styles/gallery3.module.css'
import { SRLWrapper } from 'simple-react-lightbox'

export default function Gallery() {
  const options = {
    buttons: { showDownloadButton: false, showFullscreenButton: false },
  }
  return (
    <div className={styles.gallery} id="gallerie">
      <SRLWrapper options={options}>
        <div className={styles.images}>
          <h2>Gallery</h2>
          <div className={`${styles.horizontalna} ${styles.hor1}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="horizontalna1.jpg">
                    <img src="horizontalna1.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.vertikalna} ${styles.ver1}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="vertikalna1.jpg">
                    <img src="vertikalna1.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.horizontalna} ${styles.hor2}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="horizontalna2.jpg">
                    <img src="horizontalna2.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.vertikalna} ${styles.ver2}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="vertikalna2.jpg">
                    <img src="vertikalna2.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.vertikalna} ${styles.ver3}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="vertikalna3.jpg">
                    <img src="vertikalna3.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.horizontalna} ${styles.hor3}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="horizontalna3.jpg">
                    <img src="horizontalna3.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.vertikalna} ${styles.ver4}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="vertikalna4.jpg">
                    <img src="vertikalna4.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className={`${styles.horizontalna} ${styles.hor4}`}>
            <div className={styles.galleryItem}>
              <div className={styles.galleryContainer}>
                <div className={styles.image}>
                  <a href="horizontalna4.jpg">
                    <img src="horizontalna4.jpg" alt="" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </SRLWrapper>
    </div>
  )
}
