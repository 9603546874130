import React from 'react'

import * as style from '../styles/timeTable.module.css'

function TimeTable() {
  return (
    <table className={style.timeTable} cellSpacing="0" cellPadding="0">
      <tbody>
        <tr>
          <td className={style.day}>Mo</td>
          <td className={style.borderRight} align="right">
            12:30-18:30
          </td>
          <td className={style.day}>Do</td>
          <td align="right">8:30-12:30</td>
        </tr>

        <tr>
          <td className={style.day}>Di</td>
          <td className={style.borderRight} align="right">
            8:30-14:00
          </td>
          <td></td>
          <td align="right">13:00-15:30</td>
        </tr>

        <tr>
          <td className={style.day}>Mi</td>
          <td className={style.borderRight} align="right">
            12:30-18:30
          </td>
          <td className={style.day}>Fr</td>
          <td align="right">nach Vereinbarung</td>
        </tr>
      </tbody>
    </table>
  )
}

export default TimeTable
