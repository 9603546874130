import React from 'react'
import emailjs from 'emailjs-com'

import validate from '../helper/formValidation'
import useForm from '../hooks/useForm'
import Button from './Button'
import * as style from '../styles/contactForm.module.css'

function ContactPage({ submitForm }) {
  // initializing custom hook UseForm
  const {
    handleChange,
    handleSubmit,
    values,
    schutzIsChecked,
    errors,
  } = useForm(submitForm, validate)

  const sendEmail = e => {
    e.preventDefault()
    handleSubmit()

    emailjs
      .sendForm(
        'service_xyt2039',
        'template_1ccfnaa',
        e.target,
        'user_Vi3eSIbynUv3b1CB40trD'
      )
      .then(
        result => {
          console.log(result.text)
        },
        error => {
          console.log(error.text)
        }
      )
  }

  return (
    <div className={style.formContainer} id="contact">
      <form onSubmit={sendEmail}>
        <div className={style.inputContainer}>
          <div className={style.inputBox}>
            <label htmlFor="firstName">Vorname</label>
            <input
              type="text"
              name="firstName"
              id="firstName"
              value={values.firstName}
              onChange={handleChange}
            />
            <div className={style.line}>
              <span className={style.lineColor}></span>
            </div>
            {/* if there is no value error.firstName will return true and display error message
                if there is no value then the error is set in form validation 
                and handled on submit in useForm custom hook*/}
            {errors.firstName && (
              <p className={style.errorMessage}>{errors.firstName}</p>
            )}
          </div>
          <div className={style.inputBox}>
            <label htmlFor="lastName">Nachname</label>
            <input
              type="text"
              name="lastName"
              id="lastName"
              value={values.lastName}
              onChange={handleChange}
            />
            <div className={style.line}>
              <span className={style.lineColor}></span>
            </div>
          </div>
          <div className={style.inputBox}>
            <label htmlFor="email">E-Mail</label>
            <input
              type="text"
              name="email"
              id="email"
              value={values.email}
              onChange={handleChange}
            />
            <div className={style.line}>
              <span className={style.lineColor}></span>
            </div>
            {errors.email && (
              <p className={style.errorMessage}>{errors.email}</p>
            )}
          </div>
          <div className={style.inputBox}>
            <label htmlFor="phone">Telefonummer</label>
            <input
              type="tel"
              name="phone"
              id="phone"
              value={values.phone}
              onChange={handleChange}
            />
            <div className={style.line}>
              <span className={style.lineColor}></span>
            </div>
            {errors.phone && (
              <p className={style.errorMessage}>{errors.phone}</p>
            )}
          </div>
          <div className={style.textBox}>
            <label htmlFor="message">Nachricht</label>
            <textarea
              name="message"
              id="message"
              rows="10"
              value={values.message}
              onChange={handleChange}
            />
            <div className={style.line}>
              <span className={style.lineColor}></span>
            </div>
            {errors.message && (
              <p className={style.errorMessage}>{errors.message}</p>
            )}
          </div>
          <div className={style.datenSchutz}>
            <input
              type="checkbox"
              id="datenschutz"
              name="datenschutz"
              value="datenschutz"
              checked={schutzIsChecked}
              onChange={handleChange}
            />
            <label htmlFor="datenschutz">
              Ich erkenne die Datenschutzerklärung der Webseite
            </label>
          </div>
          {errors.datenSchutz && (
            <p className={style.datenSchutzError}>{errors.datenSchutz}</p>
          )}
          <div className={style.submitContainer}>
            <Button name="senden" />
          </div>
        </div>
      </form>
    </div>
  )
}

export default ContactPage
