import React, { useState, useEffect, useCallback } from 'react'

import ContactDetailsGrid from './ContactDetailsGrid'
import ContactForm from './ContactForm'
import Button from './Button'
import Modal from './Modal'
import * as styles from '../styles/contact.module.css'

export default function ContactGrid() {
  const [isSubmitted, setIsSubmitted] = useState(false)

  const submitForm = useCallback(() => {
    setIsSubmitted(true)
  }, [setIsSubmitted])

  useEffect(() => {
    // setting submitted to false to remove success message after 3seconds
    setTimeout(() => {
      setIsSubmitted(false)
    }, 2500)
  }, [isSubmitted])

  return (
    <div className={styles.wrap} id="contact">
      <div className={styles.container}>
        <ContactDetailsGrid />

        <h3 className={styles.fragen}>
          Sie haben fragen? Kontaktieren Sie uns!
        </h3>
        <div className={styles.form}>
          <ContactForm submitForm={submitForm} a />
        </div>

        <div className={styles.buttons}>
          <a
            href="https://www.doctolib.de/zahnarztpraxis/berlin/helledent?utm_campaign=website-button&utm_source=helledent-website-button&utm_medium=referral&utm_content=option-8&utm_term=helledent"
            target="_blank" rel="noopener"
          >
            <Button name="termin vereinbaren" />
          </a>
          <a href="Anamnesebogen.pdf" download>
            <Button name={'ANAMNESE PDF'} />
          </a>
        </div>
      </div>

      {!isSubmitted ? '' : <Modal />}
    </div>
  )
}
