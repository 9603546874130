// extracted by mini-css-extract-plugin
export var homePage = "home-module--homePage--sSoVP";
export var line = "home-module--line--1nlH7";
export var line2 = "home-module--line2--2-kOx";
export var willkommen = "home-module--willkommen--2Y2yM";
export var greenSquare = "home-module--greenSquare--1dpT2";
export var square1 = "home-module--square1--1boJW";
export var square2 = "home-module--square2--1IH-c";
export var square3 = "home-module--square3--1O67u";
export var timeL = "home-module--timeL--216V3";
export var timeR = "home-module--timeR--33r2T";
export var mo = "home-module--mo--3hTl2";
export var moTime = "home-module--moTime--5IODP";
export var doTime = "home-module--doTime--2DQMC";
export var doDay = "home-module--doDay--5-ren";
export var doTime2 = "home-module--doTime2--1WhBY";
export var frTime = "home-module--frTime--2ced-";
export var frDay = "home-module--frDay--3f5KY";
export var line3 = "home-module--line3--SHufV";
export var lineSepa = "home-module--lineSepa--2ZhDy";
export var lineSepa2 = "home-module--lineSepa2--J7Fji";