import React, { useState, useEffect } from 'react'

import Arrow from '../assets/icons/CaretRight'
import * as style from '../styles/service.module.css'

const Service = ({ title, text, icon, id }) => {
  // creating state to keep track of hover state to change svg icon color
  const [hover, setHover] = useState(false)
  const [iconWidth, setIconWidth] = useState('')

  const changeHoverState = () => {
    if (!hover) {
      setHover(true)
    } else {
      setHover(false)
    }
  }

  const defaultIcon = '35px'
  const recallSys = '40px'
  const prophylaxe = '45px'
  const rontgentechnick = '40px'
  const implantologie = '30px'

  const changeWidth = () => {
    if (id === 1) {
      setIconWidth(recallSys)
    } else if (id === 3) {
      setIconWidth(prophylaxe)
    } else if (id === 8) {
      setIconWidth(rontgentechnick)
    } else if (id === 9) {
      setIconWidth(implantologie)
    } else {
      setIconWidth(defaultIcon)
    }
  }

  useEffect(() => {
    changeWidth()
  }, [])

  return (
    <div
      className={style.card}
      role="link"
      tabIndex={id}
      onMouseEnter={changeHoverState}
      onMouseLeave={changeHoverState}
    >
      <div className={style.iconCircle}>
        <img style={{ width: iconWidth }} src={icon} alt="" />
      </div>
      <div className={style.title}>
        <Arrow
          height="30px"
          color={hover ? 'var(--dark-green)' : 'var(--light-green)'}
        />
        <h3>{title}</h3>
      </div>
      <div className={style.line}></div>
      <p>{text}</p>
    </div>
  )
}

export default Service
