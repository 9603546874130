import React, { useState, useEffect } from 'react'

import MobileTimeTable from './MobileTimeTable'
import TimeTable from './TimeTable'
import * as styles from '../styles/contact.module.css'

let browserWidth

if (typeof window !== 'undefined') {
  browserWidth = window.innerWidth
}

function ContactDetailsGrid() {
  const [width, setWidth] = useState(browserWidth)
  const breakpoint = 430

  useEffect(() => {
    // listening for changes in the width of window
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])
  return (
    <>
      <h2 className={styles.kontakt}>Kontakt</h2>
      <h3 className={styles.name}>ZÄ Veneta Nenkova-Bogdanova</h3>
      <div className={styles.address}>
        <p>Louis-Lewin-Straße 8, 12627 Berlin </p>
      </div>
      <div className={styles.contact}>
        <p>
          <span> Tel.:</span> (030) 991 70 90
        </p>
      </div>
      <div className={styles.email}>
        <p>
          <span> E-Mail:</span> zahnarztpraxis@helledent.de
        </p>
        <div className={styles.contactIcons}>
          <a href="https://www.instagram.com/helledent/" target="_blank" rel="noopener">
            <img src="/instagram.png" alt="" />
          </a>
          <a
            href="https://www.facebook.com/Zahnarztpraxis-Helledent-108384721416583"
            target="_blank" rel="noopener"
          >
            <img src="/facebook.png" alt="" />
          </a>
          <a href="https://www.twitter.com/helledent" target="_blank" rel="noopener">
            <img src="/twitter.png" alt="" />
          </a>
        </div>
      </div>
      <div className={styles.howTo}>
        <p>
          Sie erreichen uns mit folgenden
          <span> öffentlichen Verkehrsmitteln:</span>
        </p>
        <p>In der Nähe beﬁndet sich die U-Bahn Haltestelle</p>
        <p>"Louis-Lewin-Str."</p>
      </div>
      <div className={styles.transport}>
        <p>
          <span> Bus:</span> Linie 154 Halltestelle Branitzer Str.
        </p>
        <p>
          <span> Tram:</span> Linie 6, 18 Halltestelle Riesaer Str.
          /Louis-Lewin-Str.
        </p>
      </div>
      <h3 className={styles.offnung}>Öffnungszeiten</h3>
      <div className={styles.table}>
        {width < breakpoint ? <MobileTimeTable /> : <TimeTable />}
      </div>
    </>
  )
}

export default ContactDetailsGrid
