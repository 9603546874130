import React from 'react'
import * as styles from '../styles/uber.module.css'
import { AnchorLink } from 'gatsby-plugin-anchor-links'
import Button from '../components/Button'

export default function UberUns() {
  return (
    <div className={styles.uberUns} id="uberUns">
      <img src="veneta2.jpg" alt="photo of Veneta" />
      <div className={styles.info}>
        <h2>Über uns</h2>
        <p>
          Ich bin eine mehrsprachige Zahnärztin (Deutsch, Englisch, Bulgarisch
          und etwas Russisch) und verfüge über eine langjährige Berufserfahrung.
          Nach dem Studium habe ich im Jahr 2000 zusätzlich 3 Jahre an der
          Universität für Zahnmedizin in Plovdiv gearbeitet und mein
          Fachzahnarztdiplom gemacht. Seitdem besuche ich regelmäßig
          Fortbildungen um mich weiter zu qualifizieren.{' '}
        </p>
        <AnchorLink to="/Mehr">
          <Button
            className={styles.mehrButton}
            color="#3f7b3b"
            bg="white"
            name="Mehr..."
          />
        </AnchorLink>
      </div>
    </div>
  )
}
