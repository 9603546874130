import React, { useState, useEffect } from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Service from './Service'
import * as style from '../styles/servicesPage.module.css'
import ServiceAlternate from './ServiceAlternate'

const services = [
  {
    id: 1,
    title: 'Recall-System',
    text: 'Erinnerung an die nächste Kontrolluntersuchung',
    slug: '/recall-system',
    img: '/recall.png',
  },
  {
    id: 2,
    title: 'Kinder und Alterszahnheilkunde',
    text: 'Allgemeine Behandlung der ganzen Familie',
    slug: '/kinder-und-alterszahnheilkunde',
    img: '/kinder.png',
  },
  {
    id: 3,
    title: 'Individuelle Prophylaxe',
    text: 'für Kinder, Jugendliche und Erwachsene',
    slug: '/individuelle-prophylaxe',
    img: '/prophylaxe.png',
  },
  {
    id: 4,
    title: 'Ästhetische Zahnmedizin',
    text: 'Zahnaufhellung, Veneers, metallfreier Zahnersatz',
    slug: '/asthetische-zahnmedizin',
    img: '/zahnmedizin.png',
  },

  {
    id: 5,
    title: 'Amalgamentfernung',
    text: 'Durchführung nach ganzheitlichem Konzept',
    slug: '/amalgamentfernung',
    img: '/amalgamentfernung.png',
  },
  {
    id: 6,
    title: 'Moderne Endodonite',
    text: 'Wurzelkanalbehandlung - durch Einsatz moderner Geräte',
    slug: '/moderne-endodonite',
    img: '/endodonite.png',
  },
  {
    id: 7,
    title: 'Parodontosebehandlung',
    text: 'Vorsorge, Diagnose, Therapie',
    slug: '/parodontosebehandlung',
    img: '/para.png',
  },
  {
    id: 8,
    title: 'Digitale Röntgentechnik',
    text: 'Befunde innerhalb von 2 Minuten direkt am Monitor',
    slug: '/digitale-röntgentechnik',
    img: 'röntgentechnik.png',
  },
  {
    id: 9,
    title: 'Prothetik und Implantologie',
    text: 'Zahnersatz auf Implantate',
    slug: '/prothetik-und-implantologie',
    img: '/implantologie.png',
  },
  {
    id: 10,
    title: 'Individuelle Schiene',
    text: 'Vorsorge, Diagnose, Therapie',
    slug: '/individuelle-schienen',
    img: '/schiene.png',
  },
  {
    id: 11,
    title: 'Lupenbrille',
    text: 'Man kann nur behandeln, was man auch sieht',
    slug: '/lupenbrille',
    img: '/9.png',
  },
  {
    id: 12,
    title: 'Haus und Pflegeheimbetreuung',
    text: 'Behandlung von gehbehinderten oder bettlägerischen Patienten',
    slug: '/haus-und-pflegeheimbetreuung',
    img: '/9.png',
  },
]

let browserWidth

if (typeof window !== 'undefined') {
  browserWidth = window.innerWidth
}

const ServicesPage = () => {
  const [width, setWidth] = useState(browserWidth)
  const breakpoint = 1083

  useEffect(() => {
    // listening for changes in the width of window
    const handleWindowResize = () => setWidth(window.innerWidth)
    window.addEventListener('resize', handleWindowResize)

    return () => window.removeEventListener('resize', handleWindowResize)
  }, [])

  return (
    <div className={style.container} id="leistungen">
      <h2>Besondere Leistungen Unserer Praxis</h2>

      {width < breakpoint ? (
        <ServiceAlternate services={services} />
      ) : (
        <div className={style.cardContainer}>
          {services.map(service => (
            <AnchorLink key={service.id} to={service.slug}>
              <Service
                title={service.title}
                text={service.text}
                icon={service.img}
                id={service.id}
              />
            </AnchorLink>
          ))}
        </div>
      )}
    </div>
  )
}

export default ServicesPage
