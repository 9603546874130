import { useState, useEffect } from 'react'

const useForm = (submitForm, validate) => {
  const [values, setValues] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: '',
  })
  const [schutzIsChecked, setSchutzIsChecked] = useState(false)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  const handleChange = e => {
    const { name, value } = e.target

    setValues({
      ...values,
      [name]: value,
    })

    if (name === 'datenschutz') {
      setSchutzIsChecked(!schutzIsChecked)
    }
  }

  const handleSubmit = () => {
    // e.preventDefault()
    setErrors(validate(values, schutzIsChecked))

    setIsSubmitting(true)
  }

  useEffect(() => {
    // checking if the errors object is empty
    if (Object.keys(errors).length === 0 && isSubmitting) {
      submitForm()
      // if the errors object is empty clearing the form
      setValues({
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        message: '',
      })

      setSchutzIsChecked(false)
    }
  }, [errors, isSubmitting, submitForm])

  return { handleChange, handleSubmit, values, errors, schutzIsChecked }
}

export default useForm
