// extracted by mini-css-extract-plugin
export var wrap = "contact-module--wrap--2nrzZ";
export var container = "contact-module--container--3AFrV";
export var kontakt = "contact-module--kontakt--2gtSa";
export var fragen = "contact-module--fragen--3haXU";
export var name = "contact-module--name--2MdaF";
export var offnung = "contact-module--offnung--hAx1h";
export var table = "contact-module--table--nq4jq";
export var form = "contact-module--form--1sjXb";
export var address = "contact-module--address--3fPC0";
export var contact = "contact-module--contact--2F6Sk";
export var email = "contact-module--email--1uetM";
export var howTo = "contact-module--howTo--1HG4O";
export var transport = "contact-module--transport--2zyyr";
export var buttons = "contact-module--buttons--HIBc8";
export var contactIcons = "contact-module--contactIcons--3KYOA";