import React from 'react'

import Check from '../assets/icons/Check'
import * as style from '../styles/modal.module.css'

function Modal() {
  return (
    <div className={style.outerContainer}>
      <div className={style.innerContainer}>
        <h3>Ihre E-Mail wurde gesendet</h3>
        <Check height={'60px'} />
      </div>
    </div>
  )
}

export default Modal
