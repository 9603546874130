// helper function for error messages

function formValidation(values, schutzIsChecked) {
  let errors = {}

  if (!values.firstName.trim()) {
    errors.firstName = 'Vorname erforderlich'
  }

  //   if (!values.lastName.trim()) {
  //     errors.lastName = 'Nachname erforderlich'
  //   }

  if (!values.email) {
    errors.email = 'E-mail erforderlich'
  } else if (!/\S+@\S+\.\S+/.test(values.email)) {
    errors.email = 'E-mail Adresse ist nicht korrekt'
  }

  // if (!/\(?[\+\(?49\)]?[ ()]?([- ()]?\d[- ()]?){10,15}/.test(values.phone)) {
  //   errors.phone = 'Bitte gib eine gültige nummer an'
  // }

  if (!values.message.trim()) {
    errors.message = 'Nachricht erforderlich'
  }

  if (schutzIsChecked === false) {
    errors.datenSchutz = 'Datenschutz muss akzeptiert werden'
  }
  return errors
}

export default formValidation
