import React from 'react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

import Arrow from '../assets/icons/CaretRight'
import * as style from '../styles/serviceAlternate.module.css'

function ServiceAlternate({ services }) {
  return (
    <div id="leistungen" className={style.serviceContainer}>
      {services.map(service => (
        <AnchorLink
          key={service.id}
          to={service.slug}
          className={style.service}
        >
          <Arrow height="30px" color="var(--light-green)" />
          <div className={style.textContainer}>
            <h3>{service.title}</h3>
            <p>{service.text}</p>
          </div>
        </AnchorLink>
      ))}
    </div>
  )
}

export default ServiceAlternate
